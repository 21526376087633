import { useState } from 'react'

import styled from 'styled-components'

import StyledModal from '~/components/modals/StyledModal'
import useTrackClick from '~/events/trackers/useTrackClick'
import useTrackScreenView from '~/events/trackers/useTrackScreenView'
import { useLogout } from '~/hooks/useAuth'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'
import { Button } from '~/ui/Button/Button'
import logger from '~/utils/logger'

interface LogoutModalProps {
  onClose: () => void
}

const LogoutModal: React.FC<LogoutModalProps> = ({ onClose }) => {
  const { processOryLogout } = useLogout()
  const t = useTranslationForNamespace('common')
  useTrackScreenView({ screen_name: 'log_out' })
  const { trackCta } = useTrackClick()
  const [loading, setLoading] = useState(false)

  const onLogout = async () => {
    try {
      setLoading(true)
      await processOryLogout()
      onClose()
    } catch (error) {
      // ideally processOryLogout code should not fail.
      logger.debug('error logging out: ', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal isOpen={true}>
      <div className='text-heading-3'>{t('notifications.logoutConfirmation')}</div>
      <LogoutActionContainer>
        <Button
          disabled={loading}
          onClick={() => {
            trackCta({
              click_name: 'log_out_confirm',
              text: t('buttons.confirmLogout'),
              type: 'button',
              destination: 'log_out',
            })
            onLogout()
          }}
        >
          {loading ? t('loadingWithEllipses') : t('buttons.confirmLogout')}
        </Button>
        <Button
          kind='secondary'
          disabled={loading}
          onClick={() => {
            trackCta({
              click_name: 'log_out_cancel',
              text: t('buttons.neverMind'),
              type: 'button',
              destination: 'log_out',
            })
            onClose()
          }}
        >
          {t('buttons.neverMind')}
        </Button>
      </LogoutActionContainer>
    </Modal>
  )
}

const LogoutActionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  margin-top: 32px;
  z-index: 1;
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    flex-direction: column;
    gap: 16px;
  }
`

const Modal = styled(StyledModal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: min(100%, 592px);
  padding: 48px 72px;
`

export default LogoutModal
